import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types';
import styles from './HeroSlim.module.scss';
import classNames from 'classnames'
import i18n from '../../i18n'

const HeroSlim = ({ image }) => {

    return (
        <div className={classNames(
            styles['HeroSlim']
        )}>
            {image.renditions && (
                <figure className={styles['HeroSlim__Container']}>
                    <img
                        width={1000}
                        height={448}
                        className={styles['HeroSlim__Image']}
                        sizes="100vw"
                        srcSet={`${image.renditions.xlarge.src} 1646w,
                                 ${image.renditions.large.src} 1200w,
                                ${image.renditions.medium.src} 600w`}
                        src={image.renditions.small.src}
                        alt={`${image.alt}`}
                        style={{
                            objectPosition: image.focal.x + ' ' + image.focal.y,
                        }}
                    />
                </figure>
            )}

            {image.caption && (
                <p className={styles['HeroSlim__Caption']}>
                    {i18n.t('generic.photo')} {image.caption}
                </p>
            )}
        </div>
    );
};

HeroSlim.propTypes = {
    image: PropTypes.object.isRequired,
};

HeroSlim.defaultProps = {
    image: {},
};

export default HeroSlim;
