import React, { PureComponent } from 'react';
import dynamic from 'next/dynamic'

import i18n from '../../i18n';
// import PropTypes from 'prop-types';
import { basePageWrap } from '../BasePage';
import styles from './GuidePage.module.scss';
import PropTypes from 'prop-types';
import ArticleEntry from '../../components/ArticleEntry';
import RichText from '../../components/RichText';
import ExtendedByline from '../../components/ExtendedByline';
import HeroSlim from '../../components/HeroSlim';
import ObjectList from '../../components/ObjectList/ObjectList';
import StreamField from '../../components/StreamField';
import MassInfo from '../../components/MassInfo';
import { trackEvent, dataLayerPush } from '../../utils/datalayer';
import Head from "next/head";
const CardSlider = dynamic(() => import('../../components/CardSlider'))

class GuidePage extends PureComponent {
    state = {};

    static defaultProps = {
        hero: null,
        articleEntry: {},
        articleHero: null,
        extendedByline: null,
        richText: null,
        extendedRichText: [],
        relatedGuides: null,
        massInfo: [],
    };

    static propTypes = {
        hero: PropTypes.object,
        articleEntry: PropTypes.object.isRequired,
        articleHero: PropTypes.object,
        extendedByline: PropTypes.object,
        richText: PropTypes.object,
        extendedRichText: PropTypes.array,
        relatedGuides: PropTypes.array,
        massInfo: PropTypes.array,
    };

    componentDidMount() {
        trackEvent('PageView', 'PageType', 'GuidePage', 'GuidePage');
        if (this.props?.extendedByline?.author?.isLocal) {
            trackEvent('localGuideView');
        }
    }

    render() {
        const {
            hero,
            articleEntry,
            extendedByline,
            richText,
            extendedRichText,
            relatedGuides,
            objectList,
            massInfo,
        } = this.props;
        return (
            <>
            <Head>
                <link rel="stylesheet" href="/third-party-styling/leaflet/leafletoriginal.css"/>
                <link rel="stylesheet" href="/third-party-styling/leaflet/leaflet.css"/>
                <link rel="stylesheet" href="/third-party-styling/leaflet/leaflet-marker-cluster.css"/>
            </Head>
            <div className={styles['GuidePage']}>
                <div className={styles['GuidePage__Main']}>
                    {hero && <HeroSlim {...hero} />}

                    <article
                        className={
                            styles['GuidePage__Section'] +
                            ' ' +
                            styles['GuidePage__Section--Grid']
                        }>
                        <div className={styles['GuidePage__Content']}>
                            <div className={styles['GuidePage__Row']}>
                                {articleEntry && (
                                    <ArticleEntry {...articleEntry} />
                                )}

                                <section
                                    className={styles['GuidePage__RichText']}>
                                    {massInfo.map((info, i) => (
                                        <MassInfo key={i} {...info} />
                                    ))}
                                    {richText && <RichText {...richText} />}
                                    {!!extendedRichText &&
                                        !!extendedRichText.length && (
                                            <StreamField
                                                items={extendedRichText}
                                            />
                                        )}
                                </section>
                            </div>
                            {extendedByline && (
                                <section
                                    className={
                                        styles['GuidePage__ExtendedByline']
                                    }>
                                    <ExtendedByline
                                        {...extendedByline}
                                        size="large"
                                    />
                                </section>
                            )}
                        </div>

                        {extendedByline && (
                            <aside className={styles['GuidePage__Aside']}>
                                <ExtendedByline
                                    {...extendedByline}
                                    size="large"
                                />
                            </aside>
                        )}
                    </article>
                </div>

                {!!objectList?.items?.length && (
                    <div className={styles['GuidePage__ObjectsContainer']}>
                        <div className={styles['GuidePage__ObjectsContent']}>
                            <ObjectList
                                {...objectList}
                                isMyList={false}
                                showRemoveButtons={false}
                            />
                        </div>
                    </div>
                )}

                {Boolean(relatedGuides) && Boolean(relatedGuides.length) && (
                    <div className={styles['GuidePage__Related']}>
                        <h2 className={styles['GuidePage__RelatedTitle']}>
                            {i18n.t('Guides.relatedWithoutFilter')}
                        </h2>
                        <CardSlider
                            items={relatedGuides}
                            visibleSlides={'auto'}
                            onCardClick={(title, category) => {

                                let bannerCategory = category;

                                if (Array.isArray(category)) {
                                    bannerCategory = category.join(',');
                                }

                                dataLayerPush({
                                    event: 'lookingForMore',
                                    bannerCategory: bannerCategory,
                                    bannerHeadline: title
                                })
                            }}
                        />
                    </div>
                )}
            </div>
            </>
        );
    }
}

export default basePageWrap(GuidePage);
